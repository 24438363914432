@import "/styles/vars.scss";

%icon {
    width: 24px;
    height: 24px;

    color: var(--c-day-night-secondary);
    transition: color .15s;
    cursor: pointer;
    transform: translate3d(0, 0, 0);
    z-index: 1;
    background: transparent;

    &::selection {
        background: transparent;
        }
    }

%hasInactiveState {
    &.__inactive {
        opacity: 0.5;
        cursor: default;
        }

    &.__active {
        color: var(--c-day-night-secondary);

        &.repeat,
        &.shuffle {
            color: $c-orange;
            }
        }
    }

:host {
    position: relative;
    display: flex;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: space-between;
    }

:host([radio-station]:not([compact])) {
    justify-content: center;

    & .play,
    & .pause {
        margin-left: 40px;
        margin-right: 40px;
        }
    }

.control-item {
    @extend %icon;
    @extend %hasInactiveState;
    color: var(--c-day-night-secondary);
    }

.play,
.pause {
    position: relative;
    margin: 0 $gap-3x;

    & wm-icon {
        position: relative;
        }
    }

:host(:not([compact])) {
    .play,
    .pause {
        width: 56px;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;

        color: #FFFFFF;
        border-radius: 50%;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            transform: scale(1);
            transition: transform .15s;
            border-radius: 50%;
            background-color: $c-orange;
            }
        }

 }


:host(.__white-c[compact]) {
    .control-item {
        color: #FFFFFF;
        }
    }



