@import "/styles/vars.scss";
@import "/styles/mixins/mq.scss";

:host {
    position: relative;
    display: block;
    height: 56px;
    width: 100%;
    z-index: 10;
    }

    :host-context(.mac-saf) {
        position: sticky;
        bottom: 0;
        transform: translateZ(0);
        }

.content {
    display: block;
    position: absolute;
    bottom: 0;
    height: 56px;
    width: 670px;
    padding: $gap-3x;
    box-sizing: border-box;
    box-shadow: 0 -1px 2px 0 rgba(0, 0, 0, 0.12);
    text-align: center;
    background-color: var(--c-portlet-bg);

    @include mq-4column {
        width: 833px;
    }

    &.__fixed {
        position: fixed;
        bottom: 20px;
        }
    }

@media local {
    wm-button + wm-button {
        margin-left: 12px;
    }
}
