@import "/styles/vars.scss";
@import "/styles/mixins/ellip.scss";

:host {
    display: inline-block;
    position: relative;
    width: 128px;
    box-sizing: border-box;
    border-radius: $gap-x;
    padding-bottom: $gap-3x;
    overflow: hidden;
    user-select: none;
}

@media local {

    wm-cover {
        border-radius: 50%;
    }

    wm-play-control {
        bottom: $gap-x;
        left: $gap-x;
    }

    wm-tico  {
        position: absolute;
        top: 8px;
        right: 6px;
        width: 32px;
        height: 32px;
        padding: 0;
    }

    wm-tico wm-icon {
        width: 24px;
        height: 24px;
    }

    slot[name="title"] {
        text-align: center;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
    }
}

slot:not([name]) {
    color: var(--c-day-night-secondary);
    margin-top: $gap-x;
}

@media local {
    wm-card-details {
        user-select: text;
        margin-top: $gap-2x;
        line-height: 16px;
        padding: 4px 12px 0;
    }
}
