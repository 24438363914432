
@media local {
    .menu-item wm-icon {
        display: flex;
        align-items: center;

        svg {
            height: 16px;
        }
    }
}
