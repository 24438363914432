@import "/styles/vars.scss";

%portlet {
    display: flex;
    flex-direction: column;
    padding: $gap-3x;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--c-portlet-bg);
    }
