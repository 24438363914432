@import "/styles/vars.scss";
@import "/styles/mixins/mq.scss";

:host {
    display: flex;
    height: 64px;
    min-width: 0;
    align-items: center;
    cursor: pointer;
    padding: $gap-2x $gap-3x;
    margin: 0 (- $gap-3x);
    text-decoration: none;
    color: var(--c-default);
    box-sizing: border-box;

    & .new-tracks {
        font-weight: bold;
        color: var(--c-friends-new-tracks);

        & + .all-tracks {
            display: none;
        }
    }

    &:hover {
        background-color: var(--c-grey-6);
        text-decoration: none;

        & .new-tracks {
            display: none;
        }

        & .all-tracks {
            display: block;
        }
    }

    &[active] {
        background-color: var(--c-menu-bg-active);
    }
}

.avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 50%;
    background-color: var(--c-stub-bg);
    width: 48px;
    height: 48px;
    margin-right: $gap-2x;
    flex-shrink: 0;
    color: var(--c-stub-icon);

    &.__empty {
        border: 1px solid var(--c-portlet-bg);
    }
}
