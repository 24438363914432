@import "/styles/vars.scss";

:host {
    display: flex;
    width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center;
    line-height: 0;
    border-radius: $gap-2x;
    /* Странное поведение — мерцают кнопки по ховеру на нижний край иконки */
    overflow: hidden;

    &:active {
        background-color: transparent;
        }
    }

wm-icon.icon-block {
    display: block;
    padding: 4px;
    opacity: 0.5;
    }

.subscription {
    cursor: pointer;
    }

.blocked {
    cursor: default;
    }

wm-progress {
    color: $c-white;
    }

@media local {
    wm-play-control {
        position: static;
        background: none;
        color: inherit;

        &:before {
            content: none;
            }
        }

    *:active {
        background-color: transparent;
        }
}
