@import "/styles/vars.scss";
@import "/styles/mixins/mob/portlet.scss";

:host {
    display: block;
    position: relative;
    @extend %portlet;

    //& + & {
    //    margin-top: $gap-3x;
    //    }

    &:last-of-type:not(.compact) {
        flex-grow: 1;
        }
    }

slot {
    display: block;
    }

@media local {
    h1 {
        display: block;
        margin: 0 0 $gap-3x 0;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        font-size: $fs-l;
        font-weight: 700;
        color: var(--c-day-night-main);
        }

    aside {
        display: block;
        position: absolute;
        right: $gap-2x;
        top: $gap-4x;
        }

    wm-slider {
        margin-left: -$gap-3x;
        margin-right: -$gap-3x;
        }
    }
