@import "/styles/vars.scss";

:host {
    display: inline-block;
    padding: $gap-2x $gap-3x;
    box-sizing: border-box;
    margin: $gap-x;
    max-width: 10em;
    border-radius: 16px;
    cursor: pointer;
    background-color: var(--c-grey-6);

    color: var(--c-day-night-main);
    font-size: 13px;
    line-height: $lh_small;
    text-overflow: ellipsis;
    }
