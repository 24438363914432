@import "/styles/vars.scss";

:host {
    display: flex;
    justify-content: center;
    pointer-events: none;
    padding: 0 $gap-2x $gap-2x;
    box-sizing: border-box;
    overflow: hidden;
    text-align: center;
    /* prevent android anti-zoom hack mh:9999px */
    max-height: 50% !important;
    }

.content {
    pointer-events: auto;
    width: 100%;
    max-width: 640px;
    vertical-align: bottom;

    -webkit-overflow-scrolling: touch;

    font-size: 15px;
    text-align: left;

    border-radius: 8px;
    background-color: var(--c-portlet-bg);
    overflow: hidden;
    box-shadow: var(--dropdown-box-shadow);
    }

#root .content,
#root .body {
    max-height: 100%;
    }

.body {
    flex: 1 1 auto;
    overflow: hidden;
    height: calc(100% - 16px);
    }

.menu-wrapper {
    display: block;
    box-sizing: border-box;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    }

.header-scroll-w {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16px;
    background-color: transparent;

    & .header-scroll {
        width: 48px;
        height: 4px;
        background: var(--c-grey-5);
        border-radius: 4px;
        }
    }

.rated {
    color: var(--dynamic-text-and-icons-base-secondary);
    display: flex;
    flex-direction: row;
    gap: $gap-3x;
    margin: $gap-3x $gap-4x;
    line-height: $lh_normal;

    p {
        margin: 0;
    }

    p + p {
        margin-top: $gap-2x;
    }
}

@media local {
    .content {
        wm-tico {
            display: flex;
            padding: 10px $gap-3x 10px 48px;
            color: var(--c-day-night-secondary);
            font-weight: 400;
            font-size: 17px;
            line-height: $fs-xl;
            cursor: pointer;

            &.__done {
                color: var(--c-day-night-secondary);
                cursor: default;
                }
            }

        .item wm-icon {
            left: $gap-3x;
            height: 100%;
            }

        .item-tip {
            font-size: 12px;
            margin-top: $gap-x;
            color: var(--c-day-night-secondary);
            }

        .header {
            color: var(--c-day-night-secondary);
            padding: 14px 12px;
            font-weight: 700;
            font-size: 15px;
            line-height: $fs-xl;
            }

        .menu-item-w {
            display: block;
            }

        .menu-item-w + .menu-item-w {
            margin-top: $gap-2x;
            }

        .menu {
            display: block;
            }
        }
    }
