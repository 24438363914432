@import "/styles/vars.scss";
@import "/styles/mixins/page.scss";
@import "/styles/mixins/ellip.scss";

:host {
    @extend %page;
}

.artists,
.label {
    @extend %ellip;
}

.description {
    display: flex;
    align-items: center;
    line-height: 20px; // для вертикального выравнивания ссылок на исполнителей и остальных пунктов
}

.label, wm-page-header-counters {
    flex-grow: 0;
    white-space: nowrap;
    flex-shrink: 0;
}

.legal_track {

    & div[slot="cover"] {
        padding-left: 8px;
    }

    & div[slot="info"] {
        verflow: hidden;
        white-space: nowrap;
    }

    & wm-track-play-button {
        color: var(--c-legal-track-play-btn);

        &[single] {
            position: static;
            width: 32px;
            height: 32px;
            visibility: visible;
        }
    }

    & .from,
    & .artist,
    & .name {
        font-size: $fs-m;
        color: var(--c-default);
        cursor: pointer;
    }

    & .from,
    & .artist {
        color: var(--c-day-night-secondary);
    }

    & .feat {
        font-size: $fs-m;
    }
}

@media local {
    .legal_track {
        wm-playback i {
            background-color: var(--c-legal-track-play-btn);
        }

        wm-progress {
            color: var(--c-legal-track-play-btn);
        }
    }
}
