@import "/styles/vars.scss";

.form_i {
    width: 400px;

    & + & {
        margin-top: $gap-6x;
    }

    & label {
        display: block;
        font-size: $fs-s;
        color: var(--c-day-night-secondary);
        margin-bottom: $gap-3x;
    }

    & input {
        width: 100%;
        height: 32px;
        line-height: 30px;
        box-sizing: border-box;
        padding: 0 $gap-6x 0 $gap-3x;
        border-radius: $gap-x;
        font-size: $fs-m;
        border: 1px var(--c-input-text-focus) solid;
        background-color: var(--c-input-text-bg);
        color: var(--c-input-text-content);

        &::-ms-clear {
            display: none;
        }

        &:focus {
            outline: none;
        }

        &:invalid {
            border-color: #f00;
        }
    }

}
