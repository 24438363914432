:host {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  margin-top: 10px;
  color: var(--c-grey-4);
}

.range-wrapper {
  flex: 1;
  margin: 0 8px;
}
