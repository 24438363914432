@import "/styles/vars.scss";

:host {
    display: block;
    position: relative;
    width: 100%;
    height: 4px;
    cursor: pointer;
    }

.track {
    width: 100%;
    height: inherit;
    border-radius: 2px;
    overflow: hidden;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: inherit;
        background-color: var(--c-progress-bg);
        border-radius: 2px;
        z-index: 2;
        overflow: hidden;
        }

    &:after {
        content: '';
        position: absolute;
        top: -6px;
        bottom: -6px;
        left: 0;
        width: 100%;
        }
    }

.cache {
    position: absolute;
    top: 0;
    left: 0;
    height: inherit;
    background-color: var(--c-progress-secondary);
    border-radius: 2px;
    z-index: 3;
    }

.progress {
    position: absolute;
    top: 0;
    left: 0;
    height: inherit;
    overflow: hidden;
    background-color: $c-orange;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    z-index: 3;
    }

.thumb-cnt {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 4;
    }

.thumb-w {
    position: absolute;
    top: 0;
    z-index: 5;
    cursor: pointer;
    padding: 0;
    }

.thumb {
    border-radius: 50%;
    background-color: var(--c-drag-thumb);    
}

@mixin thumbSize($sz, $mt) {
    $szPx: $sz + 0px;
    
    .thumb-w.__size-#{$sz} {
        margin-top: $mt * 1px;
        height: $szPx;
        .thumb {
            width: $szPx;
            height: $szPx;
        }
    }
}
@include thumbSize(10, -3);
@include thumbSize(16, -6);


.track.__secondary {
    .thumb, .progress {
        background-color: var(--c-default);
    }
    
    .cache {
        background-color: var(--c-explicit-fill-on-active-track);
    }
}
