@import "/styles/vars.scss";
@import "/styles/mixins/absolute.scss";

$dot-size: 8px;

:host {
    display: block;
    text-align: center;
}

:host(.center) {
    @include absolute(top 50%, left 50%);
    margin-left: -16px;
    margin-top: -16px;
}

:host(.flex-center) {
    display: flex;
    align-items: center;
    justify-content: center;
}

:host(.restricted-center) {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 300px;
}

.dots {
    display: inline-block;
    position: relative;
    height: $dot-size;
    width: 32px;
    font-size: 0;
}

.dot {
    display: inline-block;
    height: $dot-size;
    width: $dot-size;
    border-radius: 50%;
    background-color: var(--c-day-night-secondary);

    animation: loader-slide 2s ease-in-out infinite;
    animation-delay: $time-2x;

    :host([paused="true"]) & {
        animation-play-state: paused;
    }

    &:nth-of-type(2) {
        margin: 0 4px;
        animation-delay: $time-x;
    }

    &:nth-of-type(3) {
        animation-delay: 0s;
    }
}

:host([size=big]) {
    .dots {
        height: 12px;
        width: 48px;
    }

    .dot {
        height: 12px;
        width: 12px;
    }
}

@keyframes loader-slide {
    0% {
        opacity: 0;
        transform: translateX(-100%) scale(.5);
        transform-origin: 0 50%;
    }
    25% {
        opacity: 1;
        transform: translateX(0) scale(1);
    }
    75% {
        opacity: 1;
        transform: translateX(0) scale(1);
    }
    100% {
        opacity: 0;
        transform: translateX(100%) scale(.5);
        transform-origin: 100% 50%;
    }
}
