@import "/styles/vars.scss";

:host {
    display: block;
    overflow: hidden;
    position: relative;
    flex-shrink: 0;
    margin-left: -$gap-4x;
    margin-right: -$gap-4x;

    &:after {
        position: absolute;
        content: '';
        top: -24px;
        left: -16px;
        bottom: -24px;
        right: -16px;
        box-shadow: 0px 0px 10px 25px var(--c-day-night-surface) inset;
        }
    }

.item {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 0 $gap-4x;
    transition: opacity $time-4x ease-in;
    opacity: 0;
    flex-shrink: 0;

    &.__active {
        opacity: 1;
        }
    }

wm-icon {
    border-radius: 12px;
    overflow: hidden;
    background-color: var(--c-stub-bg);
    color: var(--c-stub-icon);
    }

@media local {
    .scroller {
        overflow: hidden;
        }

    main {
        margin-left: 0;
        }

    svg {
        transform: scale(0.4);
        }

    .img {
        width: 100%;
        height: 100%;
        border-radius: 12px;

        &.__radio {
            background-repeat: no-repeat;
            background-position: center;
            background-size: 80% 80%;
            background-color: var(--c-day-night-on-surface);
            }
        }
    }

// портретный плеер
@media (min-height: 416px) and (max-width: 600px) {
    :host {
        margin-left: -$gap-6x;
        margin-right: -$gap-6x;

        &:after {
            content: none;
            }
        }

    .item {
        padding: 0 22px $gap-6x;
        opacity: 1;
        }
}


