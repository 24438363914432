
wm-menu-wrapper {
    position: fixed;
    line-height: 16px;
}

@keyframes show-menu {
    from {
        transform: translate(0, 24px);
        opacity: 0;
    }
}

@keyframes hide-menu {
    to {
        transform: translate(0, 24px);
        opacity: 0;
    }
}
