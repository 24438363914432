@import "/styles/vars.scss";


:host {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 1;
}

.paginator {
    position: absolute;
    right: $gap-2x;
    top: $gap-2x;
    left: $gap-2x;
    z-index: 1;
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: space-between;
    padding: $gap-2x $gap-x;
    color: #ddd;
    border-radius: $gap-4x;
    background-color: rgba(0, 0, 0, 0.5);
    user-select: none;
    }

.item {
    flex: none;
    // icon height
    line-height: 16px;
    }

.next,
.prev {
    cursor: pointer;
    }

    .next {
        transform: rotate(180deg);
        }

wm-image-input {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
