@import "/styles/vars.scss";
@import "/styles/mixins/ellip.scss";
@import "/styles/mixins/mq.scss";

:host {
    user-select: none;
    display: block;
    }

.player-wrapper {
    position: fixed;
    bottom: 0;
    z-index: 85;

    &.__expanded {
        top: 0;
        }
    }

.fader {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    z-index: 85;
    pointer-events: none;
    background: rgba(0, 0, 0, 0.6);
    }

@keyframes show-player {
    from {
        transform: translateY(130%);
    }
    50% {
        transform: translateY(130%);
    }
}

@keyframes hide-player {
    50% {
        transform: translateY(130%);
    }
    to {
        transform: translateY(130%);
    }
}

@keyframes show-fullscreen-player {
    from {
        transform: translateY(100vh);
    }
    50% {
        transform: translateY(100vh);
    }
}

@keyframes hide-fullscreen-player {
    50% {
        transform: translateY(100vh);
    }
    to {
        transform: translateY(100vh);
    }
}

@keyframes show-fader {
    from {
        opacity: 0;
    }
}

@keyframes hide-fader {
    to {
        opacity: 0;
    }
}
