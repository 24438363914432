@import "/styles/vars.scss";
@import "/styles/mixins/absolute.scss";

:host {
    position: relative;
    border-radius: inherit;

    &:after {
        @extend %absolute;
        content: '';
        border-radius: inherit;
        border: 1px solid $c-transparent-black-004;
    }
}


img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    border-radius: inherit;
}

