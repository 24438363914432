@import "/styles/vars.scss";
@import "/styles/mixins/ellip.scss";

:host {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 670px;
    margin: 79px auto 0;
    z-index: 1;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    }

@media global {
    // чтобы стрелка вверх не перекрывала плеер
    .movetop.__show-up {
        z-index: 1;
    }
}

.content {
    &.__queue {
        &.__scroll-allowed {
            overflow-y: auto;
            touch-action: auto;
        }

        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
    }
}

@media local {
    .header-scroll-w {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 29px; // 12 + 5 + 12
        background-color: transparent;

        & .header-scroll {
            width: 45px;
            height: 5px;
            background: var(--c-grey-5);
            border-radius: 4px;
            }
        }
}

@keyframes show-queue {
    from {
        transform: translateY(100%);
    }
}

@keyframes hide-queue {
    to {
        transform: translateY(100%);
    }
}
