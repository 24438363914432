@import "/styles/vars.scss";

wm-button {
    // $line-height: 28px;
    $line-height: 32px;

    display: block;
    line-height: $line-height;
    overflow: hidden;

    &.__subscribed {
        & .line {
            transform: translateY(-$line-height);
            }
        }
    }

.line {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    white-space: nowrap;
    box-sizing: border-box;

    // min-height: 28px;
    min-height: 32px;
    padding: 6px 0;
    }

wm-tico {
    line-height: 16px;
    }
