$tiny-main-column-width: 668px;
$wide-main-column-width: 832px;

// Font Sizes
$fs-xs: 11px;
$fs-s: 12px;
$fs-m: 14px;
$fs-l: 16px;
$fs-xl: 20px;
$fs-xxl: 24px;


// Line Heights
$lh_x-small: 14px;
$lh_small: 16px;
$lh_normal: 20px;
$lh_large: 20px;
$lh_x-large: 24px;
$lh_xx-large: 28px;
$lh_xxx-large: 32px;
$lh_xxxx-large: 36px;

// Grid
$gap-half: 2px;
$gap-x: 4px;
$gap-2x: $gap-x * 2;
$gap-3x: $gap-x * 3;
$gap-4x: $gap-x * 4;
$gap-5x: $gap-x * 5;
$gap-6x: $gap-x * 6;
$gap-7x: $gap-x * 7;
$gap-8x: $gap-x * 8;
$gap-9x: $gap-x * 9;
$gap-10x: $gap-x * 10;

// colors
$c-white: #fff;
$c-black: #000;
$c-default: #333;
$c-lp: #666;
$c-main-color: #ee8208;
$c-notification: #1EBB55;
$c-orange: #FF7700;

$c-transparent-black-004: rgba(0, 0, 0, 0.04);

$c-black-opacity-32: rgba(0, 0, 0, 0.32);
$c-black-opacity-60: rgba(0, 0, 0, 0.6);
$c-black-opacity-80: rgba(0, 0, 0, 0.8);

$c-white-opacity-32: rgba(255, 255, 255, 0.32);
$c-white-opacity-60: rgba(255, 255, 255, 0.6);
$c-white-opacity-80: rgba(255, 255, 255, 0.8);


// Grays
$c-light: #f0f0f0;
$c-fog: #f5f5f5;
$c-mercury: #ccc;
$c-normal: #ddd;
$c-gray: #999;

// Blues
$c-smoke: #5c5c69;

// main color button
$button-bg-main-normal: #ee8208;
$button-bg-main-hover: #e27a0a;
$button-bg-main-active: #ee6e08;

// Animation Durations
$time-x: .15s;
$time-2x: $time-x * 2;
$time-4x: $time-x * 4;

// Animation Timing Functions
$show-curve: cubic-bezier(0, 0, .2, 1);
