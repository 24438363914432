@import "/styles/vars.scss";
@import "/styles/mixins/page.scss";

:host {
    @extend %page;
    }

.header {
    font-size: $fs-xl;
    }
