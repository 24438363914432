@use "/styles/vars.scss";

:host {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 4000;
    padding: vars.$gap-8x;

    display: flex;
    flex-direction: column;
    align-items: center;
    pointer-events: none;

    &[compact] {
        padding: vars.$gap-4x;
    }
}

.toast {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 12px;
    padding: vars.$gap-3x vars.$gap-5x;

    background: rgba(0, 0, 0, 0.8);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    border-radius: 100px;

    font-size: vars.$fs-m;
    line-height: 1.4;
    color: #fff;
    cursor: default;
    pointer-events: auto;

    :host[compact] & {
        border-radius: vars.$gap-4x;
        padding: vars.$gap-4x;
    }
}

.text {
    flex: 1 1;
}

wm-icon {
    margin-top: 2px;
}
