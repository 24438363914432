@import "/styles/vars.scss";
@import "/styles/mixins/mq.scss";

:host {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    background-color: var(--c-portlet-bg);
    border-top-left-radius: $gap-2x;
    border-top-right-radius: $gap-2x;
    }


wm-search-form {
    flex: 1 0;
}

wm-portlet {
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 0;
}

