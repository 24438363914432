@import "/styles/vars.scss";

:host {
    display: block;
    position: relative;
    }

@media local {
    wm-track {
        $anim-duration: 0.2s;

        border: 0;
        box-sizing: border-box;
        border-radius: $gap-x;
    }
}
