@import "/styles/vars.scss";

:host {
    display: block;
    position: relative;
    overflow: hidden;
}

:host([active]) wm-play-control,
:host:hover wm-play-control {
    opacity: 1;
}

.link {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer;
}

wm-play-control {
    opacity: 0;
    transition: opacity $time-2x;
}

wm-debug {
    position: absolute;
    top: $gap-x;
    right: $gap-x;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.5);
    color: $c-white;
    cursor: pointer;
    width: 16px;
    height: 16px;
    padding: $gap-x;
    border-radius: 100%;
    display: block;
    }

@media local {
    wm-debug wm-menu-wrapper {
        width: 120px;
        right: -14px;
    }
}
