@import "/styles/vars.scss";
@import "/styles/mixins/ellip.scss";
@import "/styles/mixins/absolute.scss";
@import "/styles/mixins/mq.scss";

$height: 36px;

:host {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    min-width: 0;
    border-radius: 4px;
    }

.cover {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: $height;
    height: $height;
    background-color: var(--c-stub-bg);
    color: var(--c-grey-4);
    flex-shrink: 0;
    user-select: none;
    border-radius: $gap-x;

    .img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: $gap-x;
        overflow: hidden;

        &.__radio {
            background-color: rgba(255, 255, 255, 0.32);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 35px;
            position: relative;

            &:after {
                @extend %absolute;
                content: '';
                border: 1px solid rgba(0, 0, 0, 0.04);
                border-radius: inherit;
                }
            }
        }

    & .overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, .5);
        cursor: pointer;
        color: #fff;
        border-radius: $gap-x;
        }
    }


wm-card-details {
    position: relative;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    height: $height;
    margin: 0 $gap-2x 0 $gap-3x;
    min-width: 0%;
    }

.name {
    text-overflow: ellipsis;
    overflow: hidden;

    font-weight: 600;
    font-size: 15px;
    line-height: 130%;
    color: var(--c-day-night-main);
    }

.artists {
    font-weight: 400;
    font-size: 13px;
    line-height: 130%;
    }

.artist + .artist:before {
    content: ',';
    margin-right: $gap-x;
    }

.feat {
    margin: 0 $gap-x;
    }

.info {
    text-overflow: ellipsis;
    overflow: hidden;

    font-weight: 400;
    font-size: 13px;
    line-height: 130%;
    color: var(--c-day-night-secondary);
    }

wm-rated-info {
    margin-left: $gap-x;
    height: 16px;
    width: 16px;
    }

:host(.__white-c) {
    .name,
    .info,
    wm-rated-info {
        color: #FFFFFF;
        }

    wm-rated-info {
        opacity: 0.5;
        }
    }
