@import "/styles/vars.scss";
@import "/styles/mixins/ellip.scss";

:host {
    display: inline-block;
    position: relative;
    width: 128px;

    &[size="large"] {
        width: 236px;
    }
}

@media local {
    slot[name="title"] .name {
        font-weight: 700;
        font-size: 15px;
        line-height: $lh_small;
        }

    slot[name="info"] {
        padding-right: 0;
        }

    wm-rated-info {
        margin-left: 2px;
        height: 16px;
    }

    slot[name="info"] div {
        display: block;
        color: var(--c-day-night-secondary);
        margin-right: 2px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    slot[name="info"] .artist-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
        margin-top: $gap-x;
    }

    .additional-info-wrapper {
        margin-top: 2px;
        height: 16px;
        display: flex;
        align-items: center;
    }

    .item + .item:before {
        content: '・';
    }

    .item + wm-notification-label {
        margin-left: 6px;
    }
}

wm-card-details {
    margin-top: $gap-2x;
    line-height: 16px;
}


