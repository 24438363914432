@import "/styles/vars.scss";

:host {
    display: inline-block;
    position: relative;
    width: 128px;
    }

[slot="info"] {
    display: block;
    margin-top: 4px;
}

wm-card-details {
    margin-top: $gap-2x;
    line-height: $lh_small;
    }

@media local {
    slot[name="title"] .name {
        font-weight: 700;
        font-size: 15px;
        line-height: $lh_small;
        }
    }
