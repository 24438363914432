@import "/styles/vars.scss";
@import "/styles/mixins/fader.scss";

/* Делаем обязательный отступ по краям, чтобы overflow: hidden не обрезал выносные элементы букв */
$indent: 2px;

:host {
    display: block;
    min-width: 0%;
}

slot {
    display: inherit;
    text-indent: inherit;
    padding-left: $indent;
    padding-right: $indent;

    &:empty {
        display: none;
    }
}

slot[name] {
    overflow: hidden;
    max-width: 100%;
    white-space: nowrap;
    font-size: $fs-m;
    line-height: inherit;
}

slot[name="info"] {
    color: var(--c-day-night-secondary);
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
}

@media local {
    slot[name="title"],
    slot[name="title"] a,
    slot[name="title"] div {
        font-size: $fs-m;
        color: var(--c-day-night-main);
        overflow: hidden;
        max-width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        }

    slot[name="title"] > div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;

        &.track-title {
            height: 20px;
            }
        }

    slot[name="info"] .artist-wrapper {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        }
}
