@import "/styles/mixins/mob/portlet.scss";
@import "/styles/mixins/page.scss";

:host {
    @extend %page;
    }

    .header-w {
        margin: $gap-3x 0;
    }

    .header {
        display: flex;
        flex-grow: 1;
        min-width: 0;
        align-items: center;
        justify-content: space-between;
        }

        .info-w {
            display: flex;
            align-items: center;
            flex-grow: 1;
            width: 100%;
            }

        .avatar {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            border-radius: 50%;
            background-color: var(--c-stub-bg);
            width: 64px;
            height: 64px;
            margin-right: $gap-2x;
            flex-shrink: 0;
            color: var(--c-stub-icon);
            cursor: pointer;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgba(0, 0, 0, 0.02);
                }
            }

            .img {
                display: block;
                width: 100%;
                height: 100%;
                }

        .name {
            display: inline-block;
            font-size: $fs-l;
            font-weight: 700;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            margin-bottom: $gap-half;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
                }
            }

        .title {
            color: var(--c-day-night-secondary);
            }

        .description {
            margin-top: $gap-x;
            }

        .cnt {
            flex-grow: 1;
            min-width: 0;
            }

        .actions {
            margin-left: $gap-3x;
            flex-shrink: 0;
            }

group-subscribers {
    margin-top: $gap-x;
    }
