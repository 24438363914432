@import "/styles/vars.scss";
@import "/styles/mixins/mob/portlet.scss";
@import "/styles/mixins/mq.scss";
@import "/styles/mixins/ellip.scss";

:host {
    @extend %portlet;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: $gap-3x;
    overflow-x: hidden;
    padding: $gap-3x 0;
    }

:host[best-match] {
    padding-top: 46px;
    }

.title {
    position: absolute;
    top: 16px;
    left: 16px;
    font-size: 13px;
    line-height: 16px;
    font-weight: 700;
    color: var(--c-default);
    z-index: 1;
    margin: 0;
    }

.img-overlay-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: $gap-3x;
    overflow: hidden;
    }

.img-overlay {
    position: absolute;
    top: - 50%;
    width: calc(100% + 100px);
    height: 754px; // для не квадратных обложек
    margin-left: -50px;
    filter: blur(60px);

    @include mq-4column {
        top: calc((100% - 916px) / 2);
        }
    }

:host-context(.mac-saf) { // фикс багов при скроллом для сафари
    transform: translate3d(0, 0, 0); // для корректного отображения border-raduis'ов
    z-index: 1;

    .overlay {
        backdrop-filter: blur(50px);
        }

    .img-overlay {
        filter: none;
        }
    }


.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: $gap-3x;
    background-color: rgba(0, 0, 0, .32);
    }

.image_w {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: start;
    background-color: var(--c-stub-bg);
    //width: 96px;
    //height: 96px;
    width: 126px;
    height: 126px;
    flex-shrink: 0;
    margin-right: $gap-3x;
    color: var(--c-stub-icon);
    border-radius: $gap-2x;
    overflow: hidden;

    &.__small {
        width: 72px;
        height: 72px;
        }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        }
    }

:host[type="artist"] .image_w {
    border-radius: 50%;
}

    .image {
        display: block;
        width: 100%;
        height: 100%;
        }

    wm-debug {
        position: absolute;
        top: $gap-x;
        right: $gap-x;
        z-index: 2;
        background-color: rgba(0, 0, 0, 0.5);
        color: $c-white;
        cursor: pointer;
        width: 16px;
        height: 16px;
        padding: $gap-x;
        border-radius: 100%;
        display: block;
        }

.content {
    // display: block;
    flex-grow: 1;
    min-width: 0;
    text-align: left;
    z-index: 1;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    min-height: 126px;
    }

.artist-img {
    margin-right: 24px;
    }

@media local {
    wm-debug wm-menu-wrapper {
        width: 120px;
        right: -14px;
        }

    .type {
        display: flex;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex-grow: 1;
        font-size: 13px;
        line-height: $lh_small;
        font-weight: 700;
        padding-bottom: 8px;
        }

    .name-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
        }

    .name {
        font-size: $fs-xl;
        line-height: $lh_x-large;
        font-weight: 700;
        color: var(--c-default);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        }

    .description {
        display: block;
        margin-bottom: $gap-3x;
        margin-top: $gap-x;
        min-height: $gap-4x;

        font-weight: 400;
        font-size: 15px;
        line-height: 16px;
        color: var(--c-day-night-secondary);

        & .artists + wm-page-header-counters:not(:empty),
        & .link + wm-page-header-counters:not(:empty),
        & .link + .label,
        & .artists + .label,
        & .label + .label {
            &:before {
                content: '・';
                }
            }
        }

    .description a {
        color: var(--c-day-night-secondary);
        }

    //.description.__white,
    //.description.__white a {
    //    color: #FFFFFF;
    //}

    .icon-stub {
        color: var(--c-stub-icon);
    }

    wm-rated-info {
        display: inline-block;
        height: 20px;
        margin-top: 2px;
        margin-left: 4px;

        //&.__white {
        //    color: #FFFFFF;
        //    opacity: 0.5;
        //}
        }

    wm-notification-label {
        margin-left: 8px;
        margin-top: 1px;
        }
    }

wm-page-header-actions.__left {
    margin-left: -84px;
    }

//:host([image]) {
//    .name,
//    slot[name="content"],
//    .title {
//        color: #FFFFFF;
//        }
//    }
