@import "/styles/vars.scss";

:host {
    display: block;
}

wm-artist-card,
wm-album-card,
wm-playlist-card {
    flex-shrink: 0;
    margin-right: $gap-3x;
    cursor: pointer;
    }
