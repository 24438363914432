@import "/styles/vars.scss";

.cnt {
    display: flex;
    width: 600px;
    white-space: normal;
    }

    .left {
        flex-shrink: 0;
        margin-right: $gap-3x;
        }

    .right {
        flex-grow: 1;
        min-width: 0;
        }

        .title {
            font-size: $fs-l;
            margin-bottom: $gap-x;
            }

            .name {

                }

            .ensemble {
                color: var(--c-day-night-secondary);
                }

        .owner {
            margin-bottom: $gap-3x;
            color: var(--c-day-night-secondary);
            }

        .text {
            margin-bottom: $gap-3x;
            line-height: 1.3;
            }

        .price {

            }

.info {
    width: 600px;
    margin-top: $gap-3x;
    font-size: $fs-s;
    color: var(--c-day-night-secondary);
    white-space: normal;
    }
