@import "/styles/mixins/ellip.scss";
@import "/styles/vars.scss";

:host {
    display: flex;
    align-items: center;
}

.friends {
    flex-shrink: 0;
    display: flex;
    flex-direction: row-reverse;
    margin-left: $gap-2x;
    margin-right: $gap-x;

    &:empty {
        display: none;
    }
}

.label {
    @extend %ellip;
    flex-grow: 1;
    font-size: $fs-s;
    color: var(--c-day-night-secondary);
}

.img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border: 2px var(--c-portlet-bg) solid;
    border-radius: 50%;
    overflow: hidden;
    margin-left: -$gap-2x;
    background-color: var(--c-stub-bg);
    color: var(--c-stub-icon);
}

wm-image {
    width: 100%;
    height: 100%;
}
