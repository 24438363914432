@import "/styles/vars.scss";

:host {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    flex-grow: 1;
}

wm-tabs {
    margin: -12px -12px 12px;
    padding: 0 12px;
}

h1 + wm-tabs {
    margin-top: 0;
}

wm-loader {
    display:flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
}
