
:host {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: 27.5px;
    width: 100%;
    }

.duration-w {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 6px;

    & .duration {
        font-weight: 400;
        font-size: 11px;
        line-height: 130%;
        color: var(--c-day-night-secondary);
        }
    }

@media local {
    wm-range-input.__ad {
        cursor: default;

        .progress {
            background-color: var(--c-progress-adv-primary);
        }

        .thumb {
            background-color: #999;
            cursor: default;
        }

        .cache {
            background-color: var(--c-progress-adv-secondary);
        }
    }

    .tooltip.__ad {
        background-color: var(--c-drag-adv-bg);
        cursor: default;

        &:before {
            background-color: var(--c-drag-adv-bg)
        }
    }
}
