@import "/styles/vars.scss";
@import "/styles/mixins/ellip.scss";

:host {
    display: flex;
    justify-content: center;
    background-color: var(--c-day-night-surface);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    padding: 29px $gap-6x 48px;
    /** всегда меняется на значение в px в setPlayerHeight */
    height: calc(100vh - 79px);
    max-height: 313px !important;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    }

.track-info-w {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: $gap-3x;
    }

.name,
.artists,
.feat {
    font-size: 17px;
    line-height: 130%;
    font-weight: 600;
    }

.name-w {
    align-items: center;
    }

.name,
.artists {
    @extend %ellip;
    }

wm-rated-info {
    margin-left: $gap-x;
    height: 16px;
    margin-top: 1px;
    }

.artists {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    }

wm-track-add-button {
    color: var(--c-day-night-secondary);
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    margin-left: $gap-2x;
    }

wm-player-duration {
    height: 20px;
    }

wm-player-duration,
wm-player-controls,
.track-info-w {
    box-sizing: border-box;
    }

wm-player-controls {
    width: 100%;
    }

@media local {
    slot[name="info"],
    slot[name="title"] {
        padding: 0;
        }

    slot[name="info"] {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        text-align: left;
        }

    slot[name="title"] {
        & .name-and-add-btn-w {
            margin-bottom: $gap-2x;
            justify-content: space-between;
            }

        & .name-w {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            }
        }
    }

.player-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    overflow: hidden;
    margin-left: $gap-4x;
    width: 100%;
    z-index: 1;
    background-color: var(--c-day-night-surface);
    }

wm-card-details {
    width: 100%;
    }


.navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $gap-x 0 $gap-3x;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;

    & .queue-btn[disabled] {
        opacity: 0.5;
        }

    & .navigation-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        color: var(--c-day-night-secondary);
        transition: background-color 0.15s ease;
        cursor: pointer;

        &.__menu {
            position: absolute;
            right: $gap-3x;
            }

        &.__bookmark {
            position: absolute;
            left: $gap-3x;
            }

        & wm-icon {
            height: 24px;
            width: 24px;
        }
    }
}

// поднимаем плеер вверх в горизонтальной ориентации ориентации в сафари, чтобы кнопка очереди не перекрывала браузерную кнопку
:host-context(.ios-10-or-later) {
    max-height: 339px !important;
    padding-bottom: 68px;

    & .navigation {
        padding-bottom: 32px;
        }
    }

@media (min-height: 315px) {
    wm-player-controls {
        margin-top: 27px;
    }

    wm-volume {
        margin-top: 27px;
        margin-bottom: 12px;
    }
}

@media (min-height: 416px) and (max-width: 600px) {
    :host-context(.ios-10-or-later),
    :host {
        max-height: unset !important;
        flex-direction: column;
        padding-bottom: 48px;

        & .navigation {
            padding-bottom: $gap-3x;
            }
        }

    .player-info {
        width: 100%;
        margin: 0;
        }

    .track-info-w {
        margin-bottom: $gap-6x;
        flex-grow: 1;
        }

    wm-player-controls {
        flex-grow: 4;
        margin-bottom: 0;
    }
    wm-volume {
        margin-top: $gap-5x;
    }
}

@media (min-height: 600px) and (max-width: 600px) {
    wm-player-controls {
        margin: $gap-5x 0;
    }
    wm-volume {
        margin-top: 0;
        margin-bottom: $gap-5x;
    }

    .track-info-w {
        margin-bottom: $gap-8x;
        margin-top: 0;
        }
}

@media global {
    .ios wm-volume {
        display: none;
    }
}

