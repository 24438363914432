@import "/styles/vars.scss";

:host {
    display: block;
    overflow: hidden;
    position: relative;
    }

.scroller {
    display: block;
    overflow: scroll;
    overflow-y: hidden;
    width: 100%;
    position: relative;
    margin-bottom: -30px;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
        }
    }

main {
    display: flex;
    flex-wrap: nowrap;
    margin-left: $gap-3x;
    }

.gap {
    flex-shrink: 0;
    }

.header-w {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $gap-3x;
    padding: 0 $gap-3x;
    }

.header {
    display: block;
    color: var(--c-default);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    font-weight: 700;
    margin: 0;
    }

wm-button-more {
    margin-left: $gap-x;
    }

@media local {
    h1 {
        margin: 0;
        }

    // убираем отступ у предпоследнего элемента
    main > *:nth-last-child(2) {
        margin-right: 0;
        }
    }
