@import "/styles/vars.scss";

:host {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    }

.input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    opacity: 0;
    }

    .img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        }
