@import "/styles/vars.scss";
@import "/styles/mixins/ellip.scss";
@import "/styles/mixins/dropdown.scss";

:host {
    @extend %dropdown;
    display: block;
    position: absolute;
    z-index: 2000; /* over modal */
}

slot {
    position: relative;
    display: block;
    z-index: 1;
    background-color: var(--c-sm-bg);
    padding: $gap-x;
    border-radius: $gap-2x;
}

.arrow {
    display: block;
    position: absolute;
    top: -20px;
    right: 0;
    width: 40px;
    overflow: hidden;
    text-align: right;

    wm-icon {
        width: 12px;
        height: 30px;
        color: var(--c-sm-bg);
        transform: rotate(90deg);
        margin-right: 20px;
        filter: drop-shadow(0px 0px 2px var(--c-sm-boxshadow));
    }

    :host(.top) & {
        top: auto;
        bottom: -25px;

        wm-icon {
            transform: rotate(270deg);
        }
    }

}

@media local {
    slot {
        wm-tico {
            display: block;
            padding: $gap-2x $gap-2x $gap-2x 30px;
            color: var(--c-sm-default);
            font-size: $fs-m;
            border-radius: $gap-x;
            cursor: pointer;

            &:hover {
                background-color: var(--c-sm-bg-hover);
            }

            &.__done {
                color: var(--c-primary);
                cursor: default;
            }
        }

        wm-icon {
            left: $gap-2x;
            height: 100%;
        }

        .item-tip {
            font-size: 12px;
            margin-top: 4px;
            color: var(--c-secondary);
        }

        .delimiter {
            border-top: 1px var(--c-delimiter) solid;
            margin: $gap-x (-$gap-x);
        }
    }
}
