@import "/styles/vars.scss";

:host {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

i {
    display: inline-block;
    width: 3px;
    height: 16px;
    background: $c-white;
    transform-origin: 100%;
    transform: scaleY(0.1);
    border-radius: 50%;

    :host(.__white) & {
        background: $c-white;
    }

    :host(.__big) & {
        height: 32px;
    }

    :host(.__playing) & {
        animation: playback .72s ease-in-out infinite;

        &:nth-of-type(1) {
            animation-delay: -.25s;
        }

        &:nth-of-type(2) {
            animation-delay: -.4s;
        }

        &:nth-of-type(3) {
            animation-delay: -.15s;
        }
    }
}

@keyframes playback {
    0% {
        transform: scaleY(0.38);
        border-radius: 10px;
    }
    50% {
        transform: scaleY(1);
        border-radius: 10px;
    }
    100% {
        transform: scaleY(0.38);
        border-radius: 10px;
    }
}
