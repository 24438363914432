:root,
html.__ui-theme_light :root {
    /* OK styles - https://www.figma.com/file/U07ehs7YWMHxkQKAVLdP20y3/OK-styles */
    --c-grey-4: #CCCCCC;
    --c-grey-5: #DDDDDD;
    --c-grey-6: #F0F0F0;
    --c-grey-7: #F7F7F7;

    --c-transparent-black-0-8: rgba(0, 0, 0, 0.08);
    --dynamic-text-and-icons-base-secondary: rgba(46, 47, 51, 0.64);

    /* OK styles */

    --c-day-secondary: #505050;

    --c-day-night-surface: #FFFFFF;
    --c-day-night-on-surface: #F7F7F7;
    --c-day-night-main-opacity-04: rgba(0, 0, 0, 0.04);
    --c-day-night-main-opacity-08: rgba(0, 0, 0, 0.08);
    --c-day-night-main-opacity-12: rgba(0, 0, 0, 0.12);
    --c-day-night-background: #F0F0F0;

    --c-day-night-main: #000000;
    --c-day-night-secondary: #505050;

    --c-nav-create-playlist-btn-bg: rgba(255, 136, 0, 0.12);
    --c-nav-create-playlist-btn-icon: #FF8800;

    --c-button-player-bg: #ee8208;
    --c-button-player-bg-hover: #ee8208;
    --c-button-player-icon: #fff;

    --c-layer-bg: var(--c-day-night-background);
    --c-portlet-bg: var(--c-day-night-surface);
    --c-explicit-color: var(--c-grey-4);

    --c-primary: #ee8208;
    --c-default: #333;
    --c-secondary: #999;
    --c-alt: #666;

    --c-dropdown-shadow: rgba(0, 0, 0, 0.16);
    --dropdown-box-shadow: 0px 12px 48px var(--c-dropdown-shadow);

    --c-icon-default: #999;
    --c-icon-hover: #666;
    --c-icon-active: #ee8208;
    --c-icon-disabled: #ddd;

    --c-input-text-content: var(--c-day-night-secondary);
    --c-input-text-bg: var(--c-day-night-background);
    --c-input-text-placeholder: var(--c-day-night-secondary);
    --c-input-text-focus: #ddd;

    --c-card-shadow-hover: rgba(0, 0, 0, 0.1);

    --c-track-play-preview-bg: #999;

    --c-button-primary-bg: rgba(255, 136, 0, 0.12);
    --c-button-primary-bg-hover: rgba(255, 119, 0, 0.16);
    --c-button-primary-text: #FF8800;

    --c-button-secondary-bg: var(--c-day-night-main-opacity-08);
    --c-button-secondary-bg-hover: var(--c-day-night-main-opacity-12);
    --c-button-secondary-text: var(--c-day-night-secondary);
    --c-button-secondary-icon:  var(--c-day-night-secondary);

    --c-button-slider-bg: #fff;
    --c-button-slider-border: rgba(0,0,0,0.05);
    --c-button-slider-icon: #666;
    --c-button-slider-icon-hover: #ee8208;

    --c-track-bg-hover: #f5f5f5;
    --c-track-bg-active: #EBEBEB;
    --c-track-info-active: #999999;
    --c-track-opacity-disabled: 0.5;
    --c-legal-track-play-btn: rgb(153, 153, 153);
    --c-mob-track-active-bg: #f7f7f7;

    --c-explicit-fill-on-active-track: #CCCCCC;

    --c-stub-bg: #f5f5f5;
    --c-stub-icon: #ddd;

    --c-sm-boxshadow: rgba(0, 0, 0, 0.2);
    --c-sm-boxshadow-blur: 4px;
    --c-sm-bg: #fff;
    --c-sm-default: #666;
    --c-sm-bg-hover: #f5f5f5;
    --c-sm-bg-active: #f7f7f7;

    --c-delimiter: var(--c-day-night-main-opacity-08);

    --c-search-suggest-top-bg: rgba(238, 130, 8, 0.1);
    --c-search-suggest-top-bg-hover: rgba(238, 130, 8, 0.2);
    --c-search-suggest-top-text: #999;

    --c-progress-bg: #e0e0e0;
    --c-progress-primary: #ff8700;
    --c-progress-secondary: #ffc078;

    --c-progress-adv-primary: #999;
    --c-progress-adv-secondary: rgba(153, 153, 153, 0.5);

    --c-drag-outline: #fff;
    --c-drag-thumb: #ee8208;
    --c-drag-text: rgba(255, 255, 255, 0.8);
    --c-drag-current-text: #fff;
    --c-drag-bg: #ee8208;
    --c-drag-adv-bg: #999;

    --c-menu-bg-hover: #f5f5f5;
    --c-menu-bg-active: #f7f7f7;
    --c-menu-text: #666;
    --c-menu-text-active: #666;

    --c-upload-drag-bg: #f0f0f0;

    --c-scroll-thumb: #CCCCCC;

    --c-friends-new-tracks: #666;
    --c-rated-color: rgba(46, 47, 51, 0.639);
}

:root[theme=dark],
html.__ui-theme_dark :root,
html.dark-theme :root {
    /* OK styles */
    --c-grey-4: #6F6F6F;
    --c-grey-5: #404040;
    --c-grey-6: #363636;
    --c-grey-7: #202020;

    --dynamic-text-and-icons-base-secondary: rgba(255, 255, 255, 0.64);

    /* OK styles */

    --c-day-night-surface: #202020;
    --c-day-night-on-surface: #303030;
    --c-day-night-main-opacity-04: rgba(255, 255, 255, 0.04);
    --c-day-night-main-opacity-08: rgba(255, 255, 255, 0.08);
    --c-day-night-main-opacity-12: rgba(255, 255, 255, 0.12);

    --c-day-night-main: #FFFFFF;
    --c-day-night-secondary: #C0C0C0;
    --c-day-night-background: #101010;

    --c-nav-create-playlist-btn-bg:  #EBEBEB;
    --c-nav-create-playlist-btn-icon: #666666;

    --c-button-player-bg: #fff;
    --c-button-player-bg-hover: #f5f5f5;
    --c-button-player-icon: #333;

    --c-primary: #f5f5f5;
    --c-default: #f5f5f5;
    --c-secondary: #999;
    --c-alt: #666;

    --c-dropdown-shadow: rgba(0, 0, 0, 0.32);

    --c-icon-default: #999;
    --c-icon-hover: #ccc;
    --c-icon-active: #ee8208;
    --c-icon-disabled: #666;

    --c-input-text-focus: rgba(255, 255, 255, 0.08);

    --c-button-slider-border: rgba(255, 255, 255, 0.2);
    --c-card-shadow-hover: rgba(0, 0, 0, 0.2);

    --c-track-play-preview-bg: #f5f5f5;

    --c-button-primary-bg: rgba(255, 255, 255, 0.08);
    --c-button-primary-bg-hover: rgba(255, 255, 255, 0.12);
    --c-button-primary-text: var(--c-day-night-secondary);

    --c-button-slider-bg: #282828;
    --c-button-slider-icon: #f5f5f5;
    --c-button-slider-icon-hover: #fff;

    --c-track-bg-hover: #4a4a4a;
    --c-track-bg-active: #6F6F6F;
    --c-track-info-active: #FFFFFF;
    --c-track-opacity-disabled: 0.2;
    --c-legal-track-play-btn: rgb(128, 128, 128);
    --c-mob-track-active-bg: #363636;

    --c-explicit-fill-on-active-track: #CCC;

    --c-stub-bg: #444;
    --c-stub-icon: #333;

    --c-sm-boxshadow: rgba(0, 0, 0, 0.4);
    --c-sm-boxshadow-blur: 5px;
    --c-sm-bg: #333;
    --c-sm-default: #ccc;
    --c-sm-bg-hover: #4a4a4a;
    --c-sm-bg-active: #3a3a3a;

    --c-search-suggest-top-bg: #666;
    --c-search-suggest-top-bg-hover: #4a4a4a;
    --c-search-suggest-top-text: #999;

    --c-progress-bg: rgba(255, 255, 255, 0.1);
    --c-progress-primary: #fff;
    --c-progress-secondary: rgba(255, 255, 255, 0.5);

    --c-progress-adv-primary: #fff;
    --c-progress-adv-secondary: rgba(255, 255, 255, 0.5);

    --c-drag-outline: #333;
    --c-drag-thumb: #fff;
    --c-drag-text: #999;
    --c-drag-current-text: #333;
    --c-drag-bg: #fff;
    --c-drag-adv-bg: #fff;

    --c-menu-bg-hover: #444;
    --c-menu-bg-active: #444;
    --c-menu-text: #999;
    --c-menu-text-active: #f0f0f0;

    --c-upload-drag-bg: rgba(255, 255, 255, 0.1);

    --c-scroll-thumb: #404040;

    --c-friends-new-tracks: #999;
    --c-rated-color: rgba(255, 255, 255, 0.639);
}
