@import "/styles/vars.scss";
@import "/styles/mixins/ellip.scss";

:host {
    display: block;
    text-align: left;
    font-size: $fs-m;
    height: 100%;
    padding-bottom: 4px;
    }

.item {
    margin-top: $gap-x;
    }

wm-loader {
    margin-top: $gap-x;
    margin-bottom: $gap-x;
    height: 32px; // track-mini height
    }

.new-playlist {
    display: flex;
    position: relative;
    padding: $gap-x $gap-3x;
    color: var(--c-sm-default);
    font-size: $fs-m;
    border-radius: $gap-x;
    cursor: pointer;
    }

.create-playlist {
    padding: $gap-half $gap-3x;
    display: flex;
    align-items: center;
    height: 48px;

    wm-icon {
        margin: 0 $gap-3x;
        color: var(--c-secondary);
        }

    .text {
        margin-left: 10px;
        font-weight: 400;
        font-size: 17px;
        line-height: 20px;
        color: var(--c-day-night-secondary);
        }
    }

    .submit,
    .cancel {
        flex-shrink: 0;
        margin: 0 0 0 $gap-x;
        background-color: transparent;
        border: none;
        padding: 0 0 0 $gap-x;
        color: var(--c-primary);
        cursor: pointer;

        &:focus {
            outline: none;
        }
    }

    .cancel {
        color: var(--c-secondary);
    }

    .input {
        width: 100%;
        height: 36px;
        flex-grow: 1;
        box-sizing: border-box;
        padding: 0 $gap-2x;
        border: 1px solid var(--c-secondary);
        border-radius: $gap-x;

        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        background-color: var(--c-input-text-bg);
        color: var(--c-default);

        &::-ms-clear {
            display: none;
            }

        &:focus {
            outline: none;
            border-color: var(--c-input-text-focus);
            }
        }
