@import "/styles/vars.scss";

:host {
    display: flex;
    align-items: center;
    max-width: 100%;
}

.item {
    position: relative;
}

.item + .item {
    margin-left: $gap-2x;
    }

wm-menu-wrapper {
    top: 100%;
    left: 50%;
    transform: translate(-50%);
}

@media local {
    [icon="more-h"] svg {
        transform: rotate(90deg);
    }
}

@keyframes show-menu {
    from {
        opacity: 0;
        top: 150%;
    }
}

@keyframes hide-menu {
    to {
        opacity: 0;
        top: 150%;
    }
}
