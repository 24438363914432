@import "/styles/mixins/page.scss";
@import "/styles/mixins/ellip.scss";
@import "/styles/vars.scss";

:host {
    @extend %page;
    }

wm-play-button {
    margin-top: $gap-3x;
    }

.artists-and-album {
    @extend %ellip;
    display: block;
    }

@media local {
    .main wm-progress {
        color: var(--c-primary);
        }

    wm-page-header wm-track {
        slot[name="info"],
        slot[name="title"] {
            padding-left: 0;
            }
        }

    //wm-page-header[image] {
    //
    //    wm-track:hover {
    //        background: rgba(0, 0, 0, 0.08);
    //        color: #FFFFFF;
    //        }
    //
    //    slot[name="title"] a,
    //    .artist,
    //    .from,
    //    .feat,
    //    .album,
    //    .duration,
    //    .action,
    //    .action:hover,
    //    wm-explicit {
    //        color: #FFFFFF;
    //        }
    //
    //    wm-explicit {
    //        opacity: 0.5;
    //        }
    //
    //    wm-track .info.disabled,
    //    .duration.disabled,
    //    slot[name="controls"].disabled .controls-icon {
    //        opacity: 0.5;
    //        }
    //
    //    wm-menu-wrapper { // в рамках фикса бордер-радиусов у шапки в сафари
    //        position: absolute;
    //        top: 40px !important;
    //        right: -8px !important;
    //        }
    //    }
    }

wm-portlet + wm-portlet  {
    margin-top: $gap-3x;
    }
