@import "/styles/vars.scss";
@import "/styles/mixins/ellip.scss";
@import "/styles/mixins/absolute.scss";

$initialSize: 146px;

$horizontalImgSize: 46px;

:host {
    position: relative;
    display: inline-block;
    width: $initialSize;
    cursor: pointer;
    }

:host([horizontal]) {
    width: calc(100% - 16px);
    display: flex;
    align-items: center;
    }

.image_w {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: $initialSize;
    height: $initialSize;
    margin-bottom: $gap-2x;
    border-radius: 8px;
    overflow: hidden;
    background-color: var(--c-day-night-on-surface);
    transition: background-color $time-2x ease;

    :host([horizontal]) & {
        height: $horizontalImgSize;
        width: $horizontalImgSize;
        border: 1px solid var(--c-day-night-main-opacity-04);
        flex-shrink: 0;
        margin-bottom: 0;
        }

    //:host:hover & {
    //    background-color: var(--c-radio-station-card-hover);
    //    }
    }

.overlay,
.image {
    @include absolute(top 0, left 0, right 0);
    width: 100%;
    height: 100%;
    border-radius: 8px;
    transition: opacity $time-2x ease;
    }

.overlay {
    background: #000000;
    opacity: 0;

    .image_w.__active & {
        opacity: 0.25;
        }
    }

.star-w {
    @include absolute(top 9px, right 9px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border: none;
    padding: 0;
    cursor: pointer;
    color: var(--c-day-night-secondary);

    :host([horizontal]) & {
        position: static;
        flex-shrink: 0;
        margin-left: $gap-2x;
        }
    }

.image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50% 50%;

    :host([horizontal]) & {
        background-size: 70% 70%;
        }

    //&.__colored {
    //    opacity: 0;
    //    }
    //
    //.image_w.__active &,
    //:host:hover & {
    //    &.__colored {
    //        opacity: 1;
    //        }
    //
    //    &.__white,
    //    &.__black {
    //        opacity: 0;
    //        }
    //    }
    }

//@include darkTheme {
//    .image.__white {
//        opacity: 0.7;
//        }
//
//    .image.__black {
//        opacity: 0;
//        }
//}
//
//@include lightTheme {
//    .image.__black {
//        opacity: 0.6;
//        }
//
//    .image.__white {
//        opacity: 0;
//        }
//}

.name {
    @extend %ellip;
    display: block;
    flex-grow: 1;
    font-size: $fs-m;
    line-height: 19px;
    color: var(--c-day-night-main);

    :host([horizontal]) & {
        margin-left: $gap-3x;
        font-size: 15px;
        line-height: 20px;
        }
    }

wm-playback {
    width: 20px;
    }

@media local {
    wm-playback.__big i {
        height: 32px;
        }
    }
