@import "/styles/vars.scss";
@import "/styles/mixins/mq.scss";
@import "/styles/mixins/mob/portlet.scss";
@import "/styles/mixins/ellip.scss";
@import "/styles/mixins/reset-button.scss";

@media global {
    @import "/styles/themes.mob.scss";

    body {
        overflow-anchor: none;
    }
}

// common reset for button tag
@media local {
    button {
        @include reset-button;
    }
}

:host {
    position: relative;
    display: block;
    user-select: text;
    color: var(--c-day-night-main);
    background-color: var(--c-day-night-background);

    & ::-webkit-scrollbar {
        width: 12px;
        background: var(--c-scroll-bg);
        }

    & ::-webkit-scrollbar-thumb {
        background: var(--c-scroll-thumb);
        border-radius: 6px;
        border: 3px transparent solid;
        background-clip: content-box;
        height: 50px;
        }

    &:focus {
        outline: none;
        }
    }

main {
    z-index: 1;
    }

.content-container {
    display: flex;
    height: 100%;
    flex-direction: row;
    box-sizing: border-box;
    padding-bottom: 64px;
    }

.content {
    flex-shrink: 0;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    min-height: 100%;

    &.__stub {
        @extend %portlet;
        padding: 0;
        width: 100%;
        min-height: calc(100vh - 98px); // 48px mob navbar height + 50px header height;
        }
    }

.page-container {
    width: 100%;

    .page-container__wrapper {
        width: 100%;
        height: 100%;
        }
    }

@media local {
    .view_more {
        color: var(--c-primary);
        cursor: pointer;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
            }
        }

    a:hover {
        text-decoration: none;
        }

    wm-track wm-icon[icon="ico_close_16"] {
        display: none;
        }
    }

wm-toast {
    bottom: 72px; // чтобы не перекрывать плеер
}

@keyframes show-toast {
    from {
        opacity: 0;
        transform: translateY(25%);
    }
}

@keyframes hide-toast {
    to {
        opacity: 0;
        transform: translateY(0) scale(1.15);
    }
}

.bottom-menu-fader {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;

    z-index: 5000;
    pointer-events: none;
    transition: visibility 0s ease max( 0.3s, 0.2s), background-color 0.2s ease-in 0s;
    visibility: hidden;
    will-change: visibility;
    contain: strict;
    background-color: rgba(0, 0, 0, 0);

    &:before {
        content: '';
        display: block;
        height: 100%;
        overflow: hidden;
        }
    }

wm-bottom-menu {
    transform: translate(0);
    transition: transform .3s ease-in-out;
    }

.bottom-menu-fader.__open {
    transition: visibility 0s ease 0s, background-color 0.3s ease-in 0s;
    visibility: visible;
    pointer-events: auto;
    background-color: rgba(0, 0, 0, .35);

    & wm-bottom-menu {
        transform: translateY(-100%);
        }
    }
