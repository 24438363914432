@import "/styles/vars.scss";
@import "/styles/mixins/ellip.scss";
@import "/styles/mixins/absolute.scss";

:host {
    display: inline-flex;
    align-items: center;
    position: relative;
    padding-left: 22px;
    min-height: 16px;
    transition: color .15s;
    @extend %ellip;
}

:host([size="normal"]) {
    padding-left: 24px;
    min-height: 20px;
}

:host([size="medium"]) {
    padding-left: 30px;
    min-height: 24px;
}

:host([size="12"]) {
    padding-left: 16px;
    min-height: 16px;

    wm-icon {
        top: unset;
    }
}

:host(.tx-ellip) slot {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

wm-icon {
    @include absolute(top 0, left 0);
    transition: none;
}

slot {
    display: block;
    /* Safari триггерит mouseleave при наведении на контент слота */
    pointer-events: none;
}
