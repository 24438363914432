@import "/styles/vars.scss";

.header-w {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $gap-2x;

    & h1 {
        margin: 0 $gap-x 0 0;
        }

    & .create-playlist-btn {
        margin-left: $gap-x;
        overflow: hidden;
        }
    }
