@import "/styles/vars.scss";
@import "/styles/mixins/ellip.scss";

:host {
    padding: 2px 12px;
    display: flex;
    align-items: center;
    }

.cover {
    width: 48px;
    height: 48px;
    overflow: hidden;
    margin-right: 10px;
    }

.cover,
.stub {
    border-radius: 8px;
    flex-shrink: 0;

    :host(.__artist) & {
        border-radius: 50%;
        }
    }

.title {
    @extend %ellip;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    color: var(--c-day-night-secondary);
    }

.stub {
    position: relative;
    color: var(--c-day-night-secondary);
    background-color: var(--c-grey-6);

    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border-radius: inherit;
        border: 1px solid var(--c-grey-5);
        }
    }

@media local {
    .stub svg {
        width: 32px;
        height: 32px;
        }
    }

