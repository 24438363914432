@import "/styles/vars.scss";
@import "/styles/mixins/ellip.scss";
@import "/styles/mixins/mq.scss";

:host {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    min-width: 0;
    padding: 4px;
    border-radius: 4px;
}

.cover {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: $c-gray;
    color: $c-white;
    border-radius: $gap-x;
    overflow: hidden;
    flex-shrink: 0;
    user-select: none;

    & wm-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 1;
        transition: opacity .15s;
    }
}

wm-card-details {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 40px;
    margin: 0 $gap-2x 0 $gap-3x;
    min-width: 0;
}

.name {
    margin-bottom: 4px;
}

.subscribe:hover {
    cursor: pointer;
    text-decoration: underline;
}
