@import "/styles/vars.scss";

:host {
    display: inline-block;

    padding: 0 $gap-x;
    min-width: auto;
    width: auto;
    height: 16px;
    border-radius: 4px;
    background-color: $c-notification;

    font-size: 10px;
    color: $c-white;
    line-height: 16px;
    font-weight: bold;
    text-align: center;
    }
