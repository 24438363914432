@import "/styles/mixins/page.scss";

:host {
    @extend %page;
    }

.stub {
    font-size: 15px;
    line-height: 1.25;
    }
