@import "/styles/vars.scss";

:host {
    padding-bottom: $gap-x;
    }

@media local {
    .remove {
        color: #f55 !important;
        }
    }
