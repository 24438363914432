@import "/styles/vars.scss";

:host {
    display: flex;
    flex-wrap: wrap;
    margin-top: -$gap-4x;
    margin-left: -$gap-4x;

    &[horizontal] {
        margin-top: -$gap-2x;
        }
    }

.card {
    margin-top: $gap-4x;
    margin-left: $gap-4x;

    &[horizontal] {
        margin-top: $gap-2x;
        }
    }

.more {
    display: block;
    margin-top: $gap-5x;
    text-align: center;
    width: 100%;
    }
