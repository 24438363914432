:host {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-size: 16px;

    &:hover {
        transition: color .15s;
        }
    }

:host([size="sidebar"]) {
    width: 18px;
    height: 18px;
    background-size: 18px;
    }

:host([size="small"]) {
    width: 14px;
    height: 14px;
    background-size: 14px;
    }

:host([size="normal"]) {
    width: 20px;
    height: 20px;
    background-size: 20px;
    }

:host([size="medium"]) {
    width: 24px;
    height: 24px;
    background-size: 24px;
    }

:host([size="28"]) {
    width: 28px;
    height: 28px;
    background-size: 28px;
    }

:host([size="12"]) {
    width: 12px;
    height: 12px;
    background-size: 12px;
    }

:host([size="32"]) {
    width: 32px;
    height: 32px;
    background-size: 32px;
    }

:host([size="45"]) {
    width: 45px;
    height: 45px;
    background-size: 45px;
    }

:host([size="large"]) {
    width: 48px;
    height: 48px;
    background-size: 48px;
    }

:host([size="236"]) {
    width: 236px;
    height: 236px;
    background-size: 236px;
    }

// Icon Specific adjustments

svg {
    display: block;
    width: 100%;
    height: 100%;
    color: inherit;
    fill: currentColor;
    }

:host([icon="more-h"]) {
    transform: rotate(90deg);
    }

:host([icon="library_v2"]),
:host([icon="collections_v2"]),
:host([icon="radio_v2"]),
:host([icon="showcase_v2"]) {
    svg {
        fill: #999;
        }
    }
