@import "/styles/vars.scss";

:host {
    display: inline-flex;
    align-items: center;
    // height: 28px;
    height: 32px;
    padding: 0 $gap-4x;
    background: var(--c-button-primary-bg);
    color: var(--c-button-primary-text);
    cursor: pointer;
    // font-size: 13px;
    font-size: 16px;
    white-space: nowrap;
    border-radius: 16px;
    line-height: 0;
    transition: box-shadow .1s ease-in, background-color .1s ease-in;
    }

    :host:hover,
    :host(.active) {
        background: var(--c-button-primary-bg-hover);
        box-shadow: 0 0 0 1px var(--c-button-primary-bg-hover);
        }

    :host([onlyIcon="true"]) {
        padding: 0;
        // width: 28px;
        width: 32px;
        justify-content: center;
    }

    :host([size="small"]) {
        height: 24px;
        font-size: $fs-s;
        }

    :host([disabled="true"]) {
        opacity: 0.5;
        pointer-events: none;
        cursor: default;
        }

    :host([role="secondary"]) {
        background-color: var(--c-button-secondary-bg);
        color: var(--c-button-secondary-text);

        wm-icon {
            color: var(--c-button-secondary-icon);
            }
        }

    :host([role="secondary"].active),
    :host([role="secondary"]):hover {
        background-color: var(--c-button-secondary-bg-hover);
        box-shadow: 0 0 0 1px var(--c-button-secondary-bg-hover);
    }

    :host([role="promo"]) {
        background-color: #ee8208;
        color: #fff;

        wm-icon {
            color: #fff;
            }

        &:hover {
            box-shadow: 0 0 0 1px #ee8208;
            }
        }

    :host([icon]) {
        padding: 0 21px;
        }

    :host([only-icon]) {
        padding: 0;
        //width: 28px;
        width: 32px;
        justify-content: center;
        border-radius: 50%;
        }

    .icon {
        display: inline-flex;
        align-items: center;

        :host(:not([only-icon])) & {
            margin-right: $gap-x;
            }

        :host(:not([only-icon])[spacing="2x"]) & {
            margin-right: $gap-2x;
            }
        }

    wm-icon[icon="pause"] {
        position: relative;
        top: -1px;
        }

    //:host([semitransparent]) {
    //    background-color: rgba(0, 0, 0, 0.32);
    //    color: #FFFFFF;
    //
    //    &:hover {
    //        background-color: rgba(0, 0, 0, 0.60);
    //        color: #FFFFFF;
    //        box-shadow: none;
    //    }
    //
    //    wm-icon {
    //        color: #FFFFFF;
    //    }
    //}

    //:host([semitransparent][disabled]) {
    //    background-color: rgba(0, 0, 0, 0.32);
    //
    //    &:hover {
    //        background-color: rgba(0, 0, 0, 0.32);
    //    }
    //}

    //:host([role="secondary"][semitransparent].active),
    //:host([role="secondary"][semitransparent]):hover {
    //    background-color: rgba(0, 0, 0, 0.60);
    //    box-shadow: none;
    //
    //    wm-icon {
    //        color: #FFFFFF;
    //    }
    //}

//:host([role="secondary"][semitransparent][disabled].active),
//:host([role="secondary"][semitransparent][disabled]):hover {
//    background-color: rgba(0, 0, 0, 0.32);
//}

:host([type="outline"]) {
    position: relative;
    height: 32px;
    padding: 0;
    border: none;
    color: $c-orange;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    background-color: transparent;

    &:after {
        border: none;
        }

    &:hover,
    &.active {
        border: none;
        box-shadow: none;
        background: none;
        }

    &:hover:after,
    &.active:after {
        border: none;
        }
    }
