@import "/styles/vars.scss";
@import "/styles/mixins/ellip.scss";
@import "/styles/mixins/dropdown.scss";

:host {
    box-sizing: border-box;
    background-color: var(--c-portlet-bg);
    border-top-right-radius: $gap-2x;
    transition: width $time-x;
}

.submit {
    color: var(--c-icon-default);

    &.__active {
        color: var(--c-primary);
        cursor: pointer;
    }
}

.suggests {
    @extend %dropdown;
    display: block;
    position: absolute;
    z-index: 30;
    top: 60px;
    right: 16px;
    left: 16px;
    max-height: 70vh;
    overflow: auto;

    &.__loading {
        .header-item,
        .result-item {
            opacity: .5;
        }
    }
}

@keyframes show-suggests {
    from {
        opacity: 0;
        top: 48px;
    }
}

@keyframes hide-suggests {
    to {
        opacity: 0;
        top: 48px;
    }
}


.result-item,
.history-item {
    @extend %item;
}

.history-item {
    position: relative;
    margin: $gap-x;
    padding: $gap-2x $gap-5x $gap-2x $gap-2x;
    border-radius: $gap-x;
    cursor: pointer;
    font-size: $fs-m;

    &:hover {
        // background-color: var(--c-track-bg-hover);
        background-color: var(--c-sm-bg);
    }

    & .name {
        flex-grow: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    & .action {
        position: absolute;
        top: $gap-2x;
        right: $gap-2x;
        color: var(--c-icon-default);

        &:hover {
            color: var(--c-icon-hover);
        }
    }
}

.submit-item {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $fs-s;
    color: var(--c-primary);
    padding: $gap-3x $gap-2x;
    cursor: pointer;
    border-bottom: 1px var(--c-delimiter) solid;
    box-sizing: border-box;
    background-color: var(--c-search-suggest-top-bg);
    border-bottom: none;

    &.__selected {
        background-color: var(--c-search-suggest-top-bg-hover);
    }

    & .text {
        @extend %ellip-i;
        font-size: 14px;
    }

    & wm-icon {
        flex-shrink: 0;
        margin-left: $gap-x;
        transform: rotate(270deg);
    }
}

.header-item {
    position: relative;
    color: var(--c-secondary);
    font-size: $fs-s;
    line-height: 20px;
    padding: $gap-x 20px $gap-x $gap-x;
    font-weight: 700;
    margin: $gap-2x;
    cursor: pointer;
    border-radius: $gap-x;

    &.__selected {
        color: var(--c-default);
    }

    & .action {
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -8px;
        transform: rotate(270deg);
    }

    & wm-icon {
        flex-shrink: 0;
        transform: rotate(270deg);
    }
}

.items-wrapper:last-child {
    .result-item {
        margin-bottom: $gap-2x;
    }
}

.result-item {
    display: flex;
    align-items: center;

    padding: $gap-x;
    border-radius: $gap-x;
    cursor: pointer;
    font-size: $fs-m;
    margin: 0 $gap-2x;

    &.__selected {
        background-color: var(--c-track-bg-hover);
    }

    & + & {
        margin-top: 2px;
    }

    & wm-cover {
        flex-shrink: 0;
        margin-right: $gap-2x;

        &[type='artist'] {
            border-radius: 50%;
        }
    }

    & .name {
        flex-grow: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.result-item + .header-item {
    margin-top: $gap-4x;
}
