@import "/styles/vars.scss";
@import "/styles/mixins/ellip.scss";

:host {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    overflow-y: hidden;
    /** всегда меняется на значение в px в setPlayerHeight */
    height: calc(100vh - 79px);
    width: 100%;
    padding-top: 29px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    touch-action: none;
    z-index: 1;
    background-color: var(--c-day-night-surface);
    box-sizing: border-box;
    -ms-overflow-style: none;
    scrollbar-width: none;
    }

.scroller {
    overflow-y: auto;
    padding: 0 $gap-3x;
    max-height: 100%;
    overflow-anchor: none;

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
        }
    }

wm-loader {
    height: 56px;
    }

.title_w {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px $gap-3x;

    & wm-icon {
        color: var(--c-day-night-secondary);
        cursor: pointer;
        }

    & .title {
        font-weight: 700;
        font-size: 17px;
        line-height: $lh_large;
        color: var(--c-day-night-main);
        }
    }

