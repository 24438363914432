@import "/styles/vars.scss";

:host {
    position: relative;
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    min-height: 28px;
    margin-bottom: $gap-x;
    }

.input {
    font-size: $fs-xxl;
    word-break: break-all;
    }

    .input {
        flex: 1 0;
        padding: 2px $gap-2x;
        border: 1px solid var(--c-input-text-focus);
        border-radius: $gap-x;
        box-sizing: border-box;

        &::-webkit-input-placeholder {
            line-height: 1.2;
            }

        &::-ms-clear {
            display: none;
            }

        &:focus {
            outline: none;
            }
        }

.actions {
    display: flex;
    padding: 0 $gap-2x;
    min-width: 72px;
    justify-content: space-between;
    }
