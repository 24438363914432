@mixin absolute($args...)  {
    position: absolute;

    @if length($args) == 0 { // @include absolute;
        top: 0; right: 0; bottom: 0; left: 0;
    } @else {
        @each $property, $value in $args {
        #{$property}: $value;
        }
    }
}

%absolute {
    @include absolute;
    }
