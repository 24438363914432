@import "/styles/vars.scss";
@import "/styles/mixins/page.scss";

:host {
    @extend %page;
}

.header-w {
    margin: $gap-3x 0;
    }

.header {
    display: flex;
    align-items: center;

    & .info {
        display: flex;
        min-width: 0;
        align-items: center;
        cursor: pointer;
        color: var(--c-default);

        &:hover {
            text-decoration: underline;
            }
        }

    & .avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        border-radius: 50%;
        background-color: var(--c-stub-bg);
        width: 32px;
        height: 32px;
        margin-right: $gap-2x;
        flex-shrink: 0;
        color: var(--c-stub-icon);
        }

    & .img {
        display: block;
        width: 100%;
        height: 100%;
        }

    & .name {
        font-size: $fs-l;
        font-weight: 700;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        }
    }

.header + h1 {
    margin-top: $gap-4x;
}

.section {
    margin-bottom: $gap-3x;
    }
