@import "/styles/vars.scss";

$sizes: (
    track-mini: 32px, track: 48px, small: 48px,
    medium: 128px, outline: 146px, large: 236px
);

:host {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: var(--c-stub-bg);
    overflow: hidden;
    color: var(--c-stub-icon);
    }

@each $name, $size in $sizes {
  :host([size="#{$name}"]) {
        width: $size;
        height: $size;
        }
    }

:host([size="track"]),
:host-context(wm-card-cover) {
    border-radius: $gap-2x;
    }

:host([size="medium"]) wm-image,
:host([size="outline"]) wm-image {
    width: 100%;
    height: 100%;
    }

:host(:not([image])[type="nav-playlist"]) {
    color: var(--c-day-night-secondary);
    background-color: var(--c-grey-6);

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: $gap-x;
        border: 1px solid var(--c-grey-5);
        }
    }
