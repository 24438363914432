
:host {
    display: block;
    position: relative;
}

wm-menu-wrapper {
    top: 40px;
    right: -20px;
    width: 250px;
    line-height: 16px;
    color: var(--c-default);
}

@keyframes show-menu {
    from {
        transform: translate(0, 24px);
        opacity: 0;
    }
}

@keyframes hide-menu {
    to {
        transform: translate(0, 24px);
        opacity: 0;
    }
}
