%ellip-i {
    display: inline-block;
    vertical-align: bottom;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    }

%ellip {
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    }

%textWrap {
    display: block;
    max-width: 100%;
    white-space: pre-wrap;
    word-wrap: break-word;
    }
