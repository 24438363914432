@import "/styles/vars.scss";

:host {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    overflow: hidden;
    overflow-y: auto;
    white-space: nowrap;
    text-align: left;
    z-index: 30;

    &:before {
        content: '';
        display: inline-block;
        position: relative;
        height: 100%;
        vertical-align: middle;
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-color: rgba(0,0,0,.8);
    pointer-events: none;
}

.content {
    display: block;
    max-width: 648px;
    line-height: 1.4;
    border-radius: $gap-2x;
    box-shadow: 0 2px 15px rgba(#000, .6);
    background: var(--c-portlet-bg);
    font-size: $fs-m;
    z-index: 2;
}

.header {
    padding: $gap-4x $gap-6x;
    border-radius: $gap-2x $gap-2x 0 0;
    font-weight: bold;
    word-wrap: break-word;
    position: relative;
}

.close {
    position: absolute;
    top: $gap-2x;
    right: $gap-2x;
    cursor: pointer;
    height: $gap-4x;
    width: $gap-4x;
    color: #999;
    padding: $gap-2x;

    &:hover {
        color: #666;
    }
}

slot:not([name]) {
    display: block;
    padding: $gap-4x $gap-6x $gap-5x;
    word-wrap: break-word;
    word-break: break-word;
    max-height: 80vh;
    box-sizing: border-box;
    overflow: auto;
    white-space: initial;
}

.buttons {
    display: block;
    padding: $gap-4x;
    text-align: center;

    &:empty {
        display: none;
    }
}

wm-button + wm-button {
    margin-left: $gap-3x;
}

@keyframes show {
    from {
        transform: scale(0.5);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}
