@import "/styles/vars.scss";
@import "/styles/mixins/absolute.scss";


:host {
    display: inline-block;
    border-radius: 100%;
    position: relative;
    color: var(--c-primary);
}

:host(.center) {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 300px;
}

:host(.__white) {
    color: $c-white;
}

:host([spin]) svg {
    animation: spin 4s linear infinite;
}

:host([indeterminate]) circle {
    transition: none;
}

:host([cancelable]) {
    &::before, &::after {
        @include absolute(top 50%, left 50%);
        content: '';
        width: 2px;
        height: 12px;
        background: currentColor;
        margin: -6px -1px;
        transform-origin: 50% 50%;
        border-radius: 2px;
    }

    &::before {
        transform: rotate(-45deg);
    }

    &::after {
        transform: rotate(45deg);
    }
}

svg {
    vertical-align: top;
}

circle {
    fill: none;
    stroke: currentColor;
    stroke-linecap: square;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    transition: stroke-dasharray 0.3s;
}

@keyframes spin {
    from {
        transform: rotate(0);
    }

    from {
        transform: rotate(-1turn);
    }
}
