@import "/styles/vars.scss";

:host {
    display: block;
    position: relative;
    }

.more {
    display: block;
    margin-top: $gap-3x;
}

:host([with-toggle-button]) .more {
    margin-top: $gap-3x;
}

@media local {
    wm-track {
        $anim-duration: 0.2s;

        border: 0;
        box-sizing: border-box;

        main.dragging & {
            pointer-events: none;
            transition: transform $anim-duration;
        }

        &.drag {
            pointer-events: none;
            position: absolute;
            z-index: 10;
            background-color: var(--c-portlet-bg);
            transition: margin-top $anim-duration ease-out, box-shadow $anim-duration ease-out;
            will-change: margin-top, transform, opacity;

            transform: scale(1.02);
            opacity: 0.9;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);

            animation: drag-enter $anim-duration ease-out;

            &.enter {
                animation: drag-enter $anim-duration ease-out;
            }

            &.leave {
                animation: drag-leave $anim-duration ease-out forwards;
            }

            &.cancel {
                animation: drag-cancel $anim-duration ease-out forwards;
            }
        }

        &.drag-stub {
            visibility: hidden;
        }

        &.drag-up {
            transform: translateY(-100%);
        }

        &.drag-down {
            transform: translateY(100%);
        }
    }

    @keyframes drag-enter {
        from {
            transform: scale(1);
            opacity: 1;
            box-shadow: none;
        }
    }

    @keyframes drag-leave {
        to {
            transform: scale(1);
            opacity: 1;
            box-shadow: none;
        }
    }

    @keyframes drag-cancel {
        to {
            transform: scale(0.8);
            opacity: 0;
            box-shadow: none;
        }
    }

}
