@import "/styles/vars.scss";

:host {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    text-align: center;
    padding: $gap-4x;
    }

    wm-icon {
        color: #ddd;
        margin: 0 auto;
        }

@media local {

    .header {
        display: block;
        margin: $gap-5x auto 0;
        font-weight: 700;
        font-size: 17px;
        line-height: $lh_normal;
        color: var(--c-day-night-secondary);
        max-width: 80%;
        }

    .text {
        display: block;
        margin: $gap-2x auto 0 auto;
        max-width: 80%;
        font-size: 15px;
        font-weight: 400;
        line-height: $lh_normal;
        color: var(--c-day-night-secondary);

        &:empty {
            display: none;
            }
        }

    wm-button {
        margin: $gap-4x auto;

        &:empty {
            display: none;
            }
        }
    }
