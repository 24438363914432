@import "/styles/vars.scss";
@import "/styles/mixins/absolute.scss";
@import "/styles/mixins/ellip.scss";

:host {
    margin-bottom: $gap-3x;
}

wm-portlet {
    overflow: hidden;
}

.title {
    margin: 0 16px;
}

.arrow {
    margin-left: $gap-x;
    margin-top: 2px; // выравнивание по строчной букве
}

@media local {
    wm-page-header {
        & .overlay {
            transition: background-color 0.15s;
        }

        &:hover {
            cursor: pointer;

            & .overlay {
                background-color: rgba(0, 0, 0, .5);
            }
        }
    }
}

.artists {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.header.__album .description {
    @extend %ellip;

    display: flex;
    align-items: center;
    line-height: 20px; // для вертикального выравнивания ссылок на исполнителей и остальных пунктов
}

.label {
    @extend %ellip;
}

.label,
wm-page-header-counters {
    flex-grow: 0;
}

.tracks {
    display: block;
    margin-top: $gap-4x;
}

.more {
    display: block;
    margin-top: $gap-4x;
    text-decoration: none;
}

.img-overlay {
    @extend %absolute;
    z-index: 1;
}

.goto-link {
    display: none;
}
