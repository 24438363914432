@import "/styles/vars.scss";

:host {
    color: var(--c-rated-color);

    &[align=top] wm-icon {
        vertical-align: -3px;
    }
}

:host:empty {
    display: none;
}

ref:tooltip {
    --origin-x: 50%;
    --origin-y: 100%;
    --offset: -4px;
    --left: 0;
    --top: auto;

    position: absolute;
    /* Явно укажем left:0, чтобы ширина не обрезалась при расчётах */
    left: var(--left);
    top: var(--top);
    z-index: 87;
    max-width: 260px;
    display: flex;
    flex-direction: column;
    align-items: center;
    pointer-events: none;
    margin-top: var(--offset);
    transform-origin: var(--origin-x) var(--origin-y);

    &.js-from-top {
        --origin-y: 0;
        --offset: 4px;

        .tooltip-tail {
            order: -1;
            transform: scaleY(-1);
        }
    }
}

ref:tooltipTail {
    position: relative;
}

.tooltip-content {
    border-radius: 16px;
    padding: 12px 16px;
    font-weight: 700;
    font-size: $fs-m;
    line-height: $lh_normal;
    background: #2E2F33;
    color: #fff;
}

@keyframes show {
    from {
        opacity: 0;
        transform: scale(0.5);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}
