@import "/styles/vars.scss";

:host {
    display: block;
    cursor: pointer;
    flex-shrink: 0;
    width: 128px;
    margin-right: $gap-4x;
    border: 1px var(--c-day-night-background) solid;
    border-radius: 8px;
    overflow: hidden;
    padding-bottom: $gap-2x;

    &:hover {
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    }
}

.link {
    text-decoration: none;
}

@media local {
    wm-cover {
        border-radius: 0;
    }

    wm-image {
        border-radius: $gap-2x $gap-2x 0 0;
        }
}

wm-card-details {
    margin-top: $gap-2x;
    line-height: 16px;
    padding: $gap-x 9px 0;
}

.title-wrap {
    display: flex;
    flex-direction: row;
    gap: $gap-x;
    margin-bottom: $gap-half;
}

.title,
.info,
.extra {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.extra {
    margin-top: $gap-x;

    .date + .genre:before {
            content: '・';
        }
}
