
:host(.description) {
    display: inline-flex;
}

.item {
    display: inline-flex;
    align-items: center;

    & + & {

        &:before {
            content: '・';
        }
    }
}

:host(.__inline) {
    display: block;

    & .item {
        display: inline;
    }
}
