@import "/styles/vars.scss";

:host {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: $gap-3x;
    left: $gap-3x;
    width: $gap-10x;
    height: $gap-10x;
    cursor: pointer;
    z-index: 1;
    padding: $gap-2x;
    box-sizing: border-box;
    color: $c-white;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.6);
        transition: transform $time-2x, background-color $time-2x;
    }

    &:hover:before {
        transform: scale(1.1);
        background-color: rgba(0, 0, 0, 1);
    }
}

wm-icon {
    color: inherit;
    position: relative;
}

wm-playback {
    min-width: 20px;
    opacity: 1;
    transition: opacity $time-2x, transform $time-2x;
}
