@import "/styles/vars.scss";

:host {
    display: flex;
    width: inherit;
    flex-direction: column;
    flex-grow: 1;
    margin-top: $gap-3x;
}

wm-search-input {
    margin-bottom: $gap-3x;
}

wm-tabs {
    margin-bottom: $gap-2x;
}

.header-w {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & h1 {
        margin: 0 $gap-x 0 0;
        }

    & aside {
        display: flex;
        position: static;
        overflow: hidden;
        }
    }
