@import "/styles/vars.scss";
@import "/styles/mixins/ellip.scss";

:host {
    position: relative;
    display: flex;
    align-items: center;
    border-bottom: 1px var(--c-delimiter) solid;
    margin-bottom: $gap-4x;
    }

.tab {
    @extend %ellip;
    position: relative;
    padding: $gap-4x 0;
    cursor: pointer;
    font-size: $fs-m;
    line-height: $lh_small;
    color: var(--c-day-night-secondary);

    & + & {
        margin-left: $gap-2x;
        }

    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 4px;
        border-radius: 2px;
        background-color: transparent;
        }

    &:hover:before {
        background-color: var(--c-grey-5);
        }

    &.__active {
        cursor: default;
        color: var(--c-day-night-main);

        &:before {
            background-color: $c-orange;
            }
        }
    }

.tab_count {
    margin-left: $gap-x;
    color: var(--c-day-night-secondary);
    font-size: $fs-s;
    line-height: 12px;
    font-weight: 400;
    }

.current-text {
    display: none;
}

@media (min-width: 420px) {
    .current-text {
        display: inline;
    }
}
