@import "/styles/vars.scss";
@import "/styles/mixins/ellip.scss";
@import "/styles/mixins/mq.scss";

:host {
    position: absolute;
    bottom: 12px;
    left: 12px;
    right: 12px;
    box-sizing: border-box;
    height: 52px;
    padding: $gap-2x $gap-3x;

    display: flex;
    flex-wrap: wrap;
    border-radius: $gap-2x;
    flex: 1 1;
    background-color: var(--c-portlet-bg);
    }

.player-info {
    display: flex;
    border-radius: $gap-2x;;
    flex-shrink: 0;
    justify-content: space-between;
    width: 100%;
    }

.wrapper {
    display: flex;
    align-items: center;
    flex-grow: 1;
    min-width: 0;
    }


.img-overlay-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: $gap-2x;
    overflow: hidden;
    }

.img-overlay {
    position: absolute;
    top: calc((100% - 754px) / 2);
    width: calc(100% + 100px);
    height: auto;
    margin-left: -50px;
    filter: blur(60px);

    &.__radio {
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        bottom: calc((100% - 754px) / 2);
        }
    }

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: $gap-2x;
    background: rgba(0, 0, 0, 0.32);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.32), rgba(0, 0, 0, 0.32)), linear-gradient(180.05deg, rgba(255, 255, 255, 0.5) -52.91%, rgba(255, 255, 255, 0) 145.92%);
    }

:host-context(.mac-saf) { // фикс багов при скроллом для сафари
    transform: translate3d(0, 0, 0); // для корректного отображения border-raduis'ов
    z-index: 1;

    .overlay {
        backdrop-filter: blur(60px);
        }

    .img-overlay {
        filter: none;
        }
    }

@media local {
    .thumb-cnt {
        display: none;
        }
    }
