@import "/styles/vars.scss";
@import "/styles/mixins/ellip.scss";

:host {
    display: flex;
    align-items: center;
    position: relative;
    padding: $gap-x $gap-3x;
    line-height: $lh_small;
    margin-left: -$gap-3x;
    margin-right: -$gap-3x;

    &[active],
    &.__active {
        background-color: var(--c-mob-track-active-bg);
        }
    }

slot {
    display: block;
    }

slot[name="cover"] {
    flex-shrink: 0;
    position: relative;
    border-radius: $gap-2x;
    }

wm-cover.__disabled {
    opacity: 0.5;
    }

wm-cover + wm-track-play-button {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.32);
    color: $c-white;
    }

.info {
    display: block;
    flex-grow: 1;
    overflow: hidden;
    padding-left: $gap-3x;
    margin-left: 0;
    line-height: inherit;
    cursor: pointer;

    :host(.__removed) &,
    :host(.disabled) &,
    &.disabled {
        cursor: default;
        opacity: var(--c-track-opacity-disabled);
        }
    }

slot[name="controls"] {
    display: flex;

    &:empty {
        display: none;
        }
    }

.title {
    font-weight: 700;
    font-size: 15px;
    line-height: 16px;
    color: var( --c-day-night-main);
    flex-shrink: 0;
    }

.artist {
    margin-left: $gap-x;
    }

.artist,
.artists-and-album,
.title {
    @extend %ellip;
    display: block;
    }

.artist,
.artists-and-album,
.album,
.from {
    // @extend %ellip; плохо выглядит при длинном списке исполнителей
    display: block;
    }

wm-card-details {
    width: 100%;

    .track-title {
        align-items: flex-start;
    }
}



@media local {
    .action {
        display: block;
        cursor: pointer;
        color: var(--c-day-night-secondary);
        padding: $gap-2x 0;
        line-height: 0;

        &.__add {
            margin: 0 10px;
            }

        &:hover {
            text-decoration: none;
            }

        & .title {
            margin-left: 6px;
            font-size: $fs-s;
            }
        }

    .restore {
        cursor: pointer;
        }

    wm-card-details slot[name="title"] div {
        & .title {
            color: var(--c-day-night-main);
            }

        & .artist {
            color: var(--c-secondary);
            }

        & div {
            height: 20px;
            }
        }

    wm-card-details slot[name="info"] {
        display: flex;
        font-weight: 400;
        font-size: 13px;
        line-height: $lh_small;
        color: var(--c-day-night-secondary);
        }
    }

.duration {
    color: var(--c-day-night-secondary);
    margin-left: $gap-2x;
    opacity: 1;
    font-weight: 400;
    font-size: 13px;
    line-height: $lh_small;
    min-width: 36px;
    }

//:host([active]),
//:host(.__active) {
//
//    .duration,
//    .artists-and-album,
//    .artist {
//        color: var(--c-track-info-active);
//        }
//
//    wm-explicit {
//        color: var(--c-explicit-fill-on-active-track)
//        }
//    }


wm-notification-bubble {
    position: absolute;
    top: 50%;
    margin-top: -3px;
    right: -4px;
    background-color: #67ce1b;
    }

wm-rated-info {
    height: 16px;
    margin-left: $gap-x;
    }

wm-debug {
    padding: $gap-3x 0 !important;
    }
