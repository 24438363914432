@import "/styles/mixins/page.scss";
@import "/styles/vars.scss";
@import "/styles/mixins/text.scss";

:host {
    @extend %page;
}

.description {
    display: block;
}

.albums-anchor:not(:first-child) {
    margin-top: $gap-3x;
}

wm-tracks-list[with-toggle-button] {
    min-height: 278px;
}

@media local {
    slot[name="description"] {
        display: flex; // чтобы счетчик альбомов не растягивался по всей ширине
    }

    .description .item.__albums-count {
        @include link;
    }

}

wm-portlet + wm-portlet {
    margin-top: $gap-3x;
}
