@import "/styles/vars.scss";

:host {
    position: relative;
    display: flex;
    border-radius: $gap-x;
    cursor: pointer;

    &[checked] .track,
    &:hover .track {
        background-color: var(--c-track-bg-hover);
        }

    .check {
        display: flex;
        align-items: center;
        padding-right: $gap-3x;
        padding-left: $gap-3x;
        margin: -2px 0 -2px -2px;
        cursor: pointer;

        wm-icon {
            color: #CCC;
            }

        }

    .track {
        display: flex;
        height: auto; // перебить портальный стиль
        font-size: 16px;
        align-items: center;
        position: relative;
        padding: $gap-x $gap-2x $gap-x $gap-x;
        border-radius: $gap-x;
        line-height: 16px;
        margin-left: -$gap-x;
        margin-right: -$gap-x;
        flex-grow: 1;
        min-width: 0;

        .cover {
            flex-shrink: 0;
            position: relative;
            border-radius: 4px;

            &:hover wm-track-play-button,
            &.__active wm-track-play-button{
                opacity: 1;
                }
            }

        wm-track-play-button {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            color: $c-white;
            opacity: 0;
            }

        .details {
            display: block;
            flex-grow: 1;
            overflow: hidden;
            padding-left: $gap-3x;
            margin-left: 0;
            line-height: inherit;
            cursor: pointer;

            :host(.__removed) &,
            :host(.disabled) &,
            &.disabled {
                cursor: default;
                opacity: var(--c-track-opacity-disabled);
            }
        }

        .artist + .artist:before {
            content: ',';
            margin-right: 4px;
        }

        .feat {
            font-size: $fs-s;
            color: var(--c-secondary);
            margin: 0 4px;
        }

        .duration {
            font-size: $fs-s;
            color: var(--c-secondary);
            margin-left: $gap-3x;
            opacity: 1;
            line-height: 1;
        }
    }

    .overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 84px;
        cursor: pointer;
        z-index: 1;
    }

    &:not(:hover) .track[active] {
        background-color: var(--c-track-bg-active);

        .duration,
        .feat,
        .artist,
        .from,
        .album {
            color: var(--c-track-info-active);
            }

        wm-rated-info {
            color: var(--c-explicit-fill-on-active-track)
            }
        }
}

wm-rated-info {
    margin-left: 4px;
}
