@import "/styles/vars.scss";

%dropdown {
    background-color: var(--c-day-night-surface);
    border-radius: $gap-2x;
    border: 1px solid var(--c-day-night-main-opacity-08);
    box-shadow: var(--dropdown-box-shadow);
    z-index: 1;
    }

%item {
    padding: $gap-2x $gap-4x;
    margin: $gap-x;
    color: var(--c-day-night-main);
    font-size: $fs-m;
    cursor: pointer;
    border-radius: $gap-x;

    &:hover {
        background-color: var(--c-grey-6);
        }
    }
