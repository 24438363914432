@import "/styles/vars.scss";

:host {
    position: relative;
    display: block;
    height: 36px;
    line-height: 1.5;
    color: var(--c-default);
    border-radius: 16px;
    overflow: hidden;
    }

input {
    width: 100%;
    height: 36px;
    box-sizing: border-box;
    padding: 0 36px 0 36px;
    border: none;
    border-radius: inherit;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    background-color: var(--c-input-text-bg);
    color: var(--c-input-text-content);

    &::-webkit-input-placeholder {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: var(--c-input-text-placeholder);
        }

    &::-moz-placeholder {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: var(--c-input-text-placeholder);
        }

    &:-ms-input-placeholder {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: var(--c-input-text-placeholder);
        }

    &::-webkit-input-placeholder {
        line-height: 1.2;
        }

    &::-ms-clear {
        display: none;
        }

    &:focus {
        outline: none;
        }
    }

slot[name="action-left"],
slot[name="action-right"] {
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -$gap-2x;
    color: var(--c-input-text-placeholder);
    }

slot[name="action-left"] {
    left: $gap-3x;
    }

slot[name="action-right"] {
    right: $gap-3x;
    }

.search,
.clear {
    position: absolute;
    top: 50%;
    cursor: pointer;

    &:hover {
        color: var(--c-icon-hover);
        }
    }

.clear {
    right: 0;
    }
