@import "/styles/vars.scss";
@import "/styles/mixins/dropdown.scss";
@import "/styles/mixins/ellip.scss";
@import "/styles/mixins/absolute.scss";

:host {
    position: relative;
    display: flex;
    align-items: center;
    border-bottom: 1px var(--c-delimiter) solid;
    }

.tabs {
    display: flex;
    flex-shrink: 0;
    overflow: hidden;
    max-height: 48px;

    &.__wide {
        flex-grow: 1;
        }
    }

.more,
.tab {
    @extend %ellip;
    position: relative;
    height: 48px;
    box-sizing: border-box;
    margin-left: $gap-2x;
    padding: $gap-4x 0;
    cursor: pointer;
    font-size: $fs-m;
    text-align: center;
    color: var(--c-day-night-secondary);
    transition: color $time-x;

    &:before {
        content: '';
        @include absolute(right 0, bottom 0,left 0);
        height: 4px;
        border-radius: 2px;
        background-color: transparent;
        transition: background $time-x;
        }

    &:hover:before {
        background-color: var(--c-grey-5);
        }

    &.__disabled:before {
        background-color: transparent;
        }

    &.__active {
        color: var(--c-day-night-main);

        &:before {
            background-color: $c-orange;
            }
        }
    }

.tab {
    display: none;
    font-size: $fs-m;

    &.__visible {
        display: block;
        }

    &:first-child {
        margin-left: 0;
        }

    &.__disabled {
        cursor: not-allowed;
        opacity: 0.5;
        }

    &:not(.__visible),
    &.__disabled {
        pointer-events: none;
    }

    &.__active {
        cursor: default;
        }
    }

.more {
    display: none;

    &.__visible {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        opacity: 1;
        }

    &.__open .icon {
        transform: rotate(-180deg);
        }

    .icon {
        color: var(--c-day-night-secondary);
        margin-left: $gap-x;
        transition: transform $time-x;
        }
    }

.menu {
    @extend %dropdown;
    @include absolute(top 44px, right 0);

    & .item {
        @extend %item;
        height: 16px;

        &.__disabled {
            cursor: not-allowed;
            opacity: 0.5;
            pointer-events: none;
            }
        }
    }

@keyframes show-menu {
    from {
        opacity: 0;
        top: 150%;
    }
}

@keyframes hide-menu {
    to {
        opacity: 0;
        top: 150%;
    }
}
